<template>
  <div class="reset-password">
    <main>
      <div class="content">
        <router-link to="/">
          <img class="logo__large" src="@/assets/icons/logo_white.svg" alt="teamee Logo" title="teamee - Home" />
        </router-link>
        <h1 class="h3">Passwort zurücksetzen</h1>
        <form>
          <div class="input__text__label" v-if="!successMsg">
            <input
              class="input__text floating-input"
              :class="{ input__text__danger: $v.email.$error || errors.email }"
              type="mail"
              title="E-Mail"
              placeholder=" "
              v-model.trim="$v.email.$model"
            />
            <label>E-Mail</label>
            <!-- Error Message -->
            <div v-if="$v.email.$error || errors.email" class="error">
              <li v-if="errors.email" class="error-msg">{{ errors.email[0] }}</li>
              <li v-if="!$v.email.required" class="error-msg">Wir brauchen eine E-Mail Adresse von dir</li>
              <li v-if="!$v.email.email" class="error-msg">Bitte gib eine gültige E-Mail Adresse an</li>
            </div>
          </div>

          <div class="input__text__label" v-if="!successMsg">
            <input
              class="input__text floating-input"
              :class="{ input__text__danger: $v.password.$error || errors.password }"
              type="password"
              title="Passwort"
              placeholder=" "
              v-model.trim="$v.password.$model"
            />
            <label>Neues Passwort</label>
            <!-- Error Message -->
            <div v-if="$v.password.$error || errors.password" class="error">
              <li v-if="errors.password" class="error-msg">{{ errors.password[0] }}</li>
              <li v-if="!$v.password.checkPasswordPattern" class="error-msg">
                Dein Passwort sollte aus mindestend 6 Buchstaben, Zahlen und Sonderzeichen bestehen
              </li>
            </div>
          </div>

          <div class="input__text__label" v-if="!successMsg">
            <input
              class="input__text floating-input"
              :class="{ input__text__danger: $v.password_confirmation.$error || errors.password_confirmation }"
              type="password"
              title="Passwort wiederholen"
              placeholder=" "
              v-model.trim="$v.password_confirmation.$model"
            />
            <label>Passwort wiederholen</label>
            <!-- Error Message -->
            <div v-if="$v.password_confirmation.$error || errors.password_confirmation" class="error">
              <li v-if="errors.password_confirmation" class="error-msg">{{ errors.password_confirmation[0] }}</li>
              <li v-if="!$v.password_confirmation.required" class="error-msg">Bitte bestätige dein Passwort</li>
              <li v-if="!$v.password_confirmation.sameAsPassword" class="error-msg">Deine Passwortangaben müssen übereinstimmen</li>
            </div>
          </div>

          <p class="error-msg" v-if="errorMsg">{{ errorMsg }}</p>
          <p v-if="successMsg">{{ successMsg }}</p>

          <button class="cta button__cta" @click.prevent="resetPassword" :disabled="isLoading || successMsg">
            <span class="loader" v-if="isLoading"></span>
            <span class="txt" v-else>Passwort zurücksetzen</span>
          </button>
          <router-link v-if="successMsg" to="/"><button class="button__transparent">Zurück zum Login</button></router-link>
        </form>
      </div>
    </main>
    <AppFooterRegistration />
  </div>
</template>

<script>
import AppFooterRegistration from "@/components/AppFooterRegistration.vue";
import { required, email, sameAs } from "vuelidate/lib/validators";

export default {
  name: "ResetPassword",
  components: {
    AppFooterRegistration,
  },
  data() {
    return {
      email: "",
      password: "",
      password_confirmation: "",
      errors: [],
      isLoading: false,
      errorMsg: "",
      successMsg: null,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      checkPasswordPattern(password) {
        return (
          /[a-z]/.test(password) && // checks for a-z
          /[0-9]/.test(password) && // checks for 0-9
          /\W|_/.test(password) && // checks for special char
          password.length >= 6
        );
      },
    },
    password_confirmation: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    resetPassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;

        // make Api Request and wait for Response
        this.$store
          .dispatch("resetPassword", {
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            token: this.$route.query.token,
          })
          .then((response) => {
            this.isLoading = false;
            this.successMsg = response.data.msg;
            this.errorMsg = "";
          })
          .catch((error) => {
            this.errorMsg = error.response.data.msg;
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

main {
  height: calc(100vh - 83px);
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo__large {
      margin-bottom: 56px;
      @media screen and (max-width: 960px) {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    h1 {
      margin-bottom: 80px;
      @media screen and (max-width: 960px) {
        margin-bottom: 44px;
      }
    }
    form {
      width: 400px;
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: 440px;
      }
      .input__text__label {
        margin-bottom: 30px;
        @media screen and (max-width: 960px) {
          margin-bottom: 56px;
        }
      }
      .error {
        margin-top: 8px;
        margin-left: 16px;
      }
      button {
        margin-top: 56px;
        width: 100%;
      }
    }
  }
}
</style>
